import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import MapModal from './MapModal'
import StationModal from './StationModal'
import ZonesPathModal from './ZonesPathModal'
import RemoveModal from './RemoveModal'
import DeviceModal from './DeviceModal'

const MapLayerModal = ({
    modal,
    toggleModal,
    windows,
    setWindows,
    slug,
    areaUuid,
    handleNewPoint,
    path,
    handleSaveMap,
    resetMap,
    clickedStation,
    setClickedStation,
    clickedDevice,
    setClickedDevice,
    setTraversalCreate,
    setIcon,
    orgSlug,
    handleNewDevice,
}) => {
    useEffect(() => {
        if (!modal) {
            setIcon(false)
        }
    }, [modal, setIcon])
    const ModalContent = () => {
        switch (true) {
            case windows.save:
                return (
                    <MapModal
                        status="save"
                        modal={modal}
                        toggle={toggleModal}
                        saveMap={handleSaveMap}
                    />
                )
            case windows.reset:
                return (
                    <MapModal
                        status="reset"
                        modal={modal}
                        toggle={toggleModal}
                        resetMap={resetMap}
                    />
                )

            case windows.editStation:
                return (
                    <StationModal
                        slug={slug}
                        areaUuid={areaUuid}
                        toggle={() => {
                            toggleModal()
                            setClickedStation(null)
                        }}
                        status="edit"
                        station={clickedStation}
                        orgSlug={orgSlug}
                        setWindows={setWindows}
                    />
                )
            case windows.createStation:
                return (
                    <StationModal
                        slug={slug}
                        areaUuid={areaUuid}
                        toggle={toggleModal}
                        status="add"
                        handleNewPoint={handleNewPoint}
                        setTraversalCreate={setTraversalCreate}
                    />
                )
            case windows.zonesPath:
                return (
                    <ZonesPathModal
                        slug={slug}
                        setIcon={setIcon}
                        status="add"
                        toggle={toggleModal}
                        areaUuid={areaUuid}
                    />
                )
            case windows.editZonesPath:
                return (
                    <ZonesPathModal
                        areaUuid={areaUuid}
                        slug={slug}
                        status="edit"
                        path={path}
                        setIcon={setIcon}
                        toggle={toggleModal}
                        setWindows={setWindows}
                    />
                )
            case windows.createDevice:
                return (
                    <DeviceModal
                        slug={slug}
                        areaUuid={areaUuid}
                        toggle={toggleModal}
                        handleNewDevice={handleNewDevice}
                        setWindows={setWindows}
                    />
                )
            case windows.editDevice:
                return (
                    <DeviceModal
                        slug={slug}
                        areaUuid={areaUuid}
                        toggle={() => {
                            toggleModal()
                            setClickedDevice(null)
                        }}
                        device={clickedDevice}
                        setWindows={setWindows}
                    />
                )
            case windows.removeZonesPath:
                return (
                    <RemoveModal
                        slug={slug}
                        data={path}
                        type="path"
                        toggle={toggleModal}
                        areaUuid={areaUuid}
                    />
                )
            case windows.removeStation:
                return (
                    <RemoveModal
                        slug={slug}
                        areaUuid={areaUuid}
                        data={clickedStation}
                        type="station"
                        toggle={toggleModal}
                    />
                )
            case windows.removeDevice:
                return (
                    <RemoveModal
                        areaUuid={areaUuid}
                        slug={slug}
                        data={clickedDevice}
                        type="device"
                        toggle={toggleModal}
                    />
                )
            default:
                return
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggleModal} centered className="new-modals">
            <ModalBody>
                <ModalContent />
            </ModalBody>
        </Modal>
    )
}

export default MapLayerModal
