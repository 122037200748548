import React, { useState, useEffect } from 'react'
import { Text } from 'react-konva'
import { Modal, ModalBody, Button } from 'reactstrap'
import { Image as KonvaImage } from 'react-konva'
import useImage from 'use-image'
import { Html } from 'react-konva-utils'

const IndoorMapPoint = ({
    pointKey,
    editPoint,
    point,
    customIcon,
    handleDragEnd,
    handleDragStart,
    largestPoint,
    aoi,
    handleSubmit,
    slug,
    icon,
    sizeText,
    estimatedPose,
    selectedRobot,
    setDataHovered,
    handlePointSubmitSelect,
    setSelectedStation,
    ...props
}) => {
    const [imageUrl, setImageUrl] = useState(null)
    const [stationDefault] = useImage(imageUrl)
    const [defaultHover] = useImage('/svgs/map-layer/station-hover.svg')
    const [charging] = useImage('/svgs/map-layer/charging-station.svg')
    const [chargingHover] = useImage('/svgs/map-layer/charging-hover.svg')
    const [docking] = useImage('/svgs/map-layer/docking-station.svg')
    const [undocking] = useImage('/svgs/map-layer/undocking-station.svg')
    const [resting] = useImage('/svgs/map-layer/resting-station.svg')
    const [restingHover] = useImage('/svgs/map-layer/resting-hover.svg')
    const [traversal] = useImage('/svgs/map-layer/traversal-default.svg')
    const [traversalHover] = useImage('/svgs/map-layer/traversal-hover.svg')
    const [stationDisabled] = useImage('/svgs/map-layer/station-disabled.svg')
    const [chargingDisabled] = useImage('/svgs/map-layer/charging-disabled.svg')
    const [restingDisabled] = useImage('/svgs/map-layer/resting-disabled.svg')
    const [traversalDisabled] = useImage('/svgs/map-layer/traversal-disabled.svg')
    const [evacuationDefault] = useImage('/svgs/map-layer/evacuation-station.svg')
    const [evacuationHover] = useImage('/svgs/map-layer/evacuation-hover.svg')
    const [evacuationDisabled] = useImage('/svgs/map-layer/evacuation-disabled.svg')
    const [openPopover, setOpenPopover] = useState(false)
    const [highlight, sethighlight] = useState(false)
    const toggle = () => {
        setOpenPopover(!openPopover)
    }
    const iconColor = point?.icon_color ? point?.icon_color : '#BA4FFA'
    const renderCustomIcon = customIcon?.map((icon) => {
        let newImageURL = ''

        switch (icon.title) {
            case 'Shelf':
                newImageURL = '/svgs/map-layer/shelf.svg'
                break
            case 'Pallet loaded':
                newImageURL = '/svgs/map-layer/pallet-loaded.svg'
                break
            case 'Pallet unloaded':
                newImageURL = '/svgs/map-layer/pallet-unloaded.svg'
                break
            case 'Conveyor':
                newImageURL = '/svgs/map-layer/conveyor.svg'
                break
            case 'Palletiser':
                newImageURL = '/svgs/map-layer/palletiser.svg'
                break
            case 'Cleaning mission start point':
                newImageURL = '/svgs/map-layer/cleaning-mission-start-point.svg'
                break
            case 'Assembly line robot':
                newImageURL = '/svgs/map-layer/assembly-line-robot.svg'
                break
            case 'Assembly line person':
                newImageURL = '/svgs/map-layer/assembly-line-person.svg'
                break
            case 'Assembly line person and robot':
                newImageURL = '/svgs/map-layer/assembly-line-person-robot.svg'
                break
            default:
                newImageURL = icon.icon
                break
        }

        return { ...icon, icon: newImageURL }
    })


    const customisedIcon = Object?.assign(
        {},
        ...(renderCustomIcon || [])?.filter((icon) => icon.uuid === point.custom_icon)
    )
    const [customStationIcon] = useImage(customisedIcon.system_generated ? imageUrl : customisedIcon.icon)

    const modifySvgColor = () => {
        const svgURL =
            customisedIcon.system_generated === true
                ? customisedIcon.icon
                : '/svgs/map-layer/station-default.svg'
        fetch(svgURL)
            .then((response) => response.text())
            .then((svg) => {
                svg = svg.replace(/stroke="[^"]+"/g, `stroke="${iconColor}"`)
                svg = svg.replace(/fill="#BA4FFA"/g, `fill="${iconColor}"`)
                const dataUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg)
                setImageUrl(dataUrl)
            })
    }

    useEffect(() => {
        // Modify SVG color whenever the color changes
        modifySvgColor(iconColor)
    }, [iconColor]) // eslint-disable-line react-hooks/exhaustive-deps
    

    // function pointNameInPixels(str) {
    //     let canvas = document.createElement('canvas')
    //     let ctx = canvas.getContext('2d', { willReadtFrequently: true })
    //     let width = ctx.measureText(str).width
    //     return width
    // }

    const showImage = () => {
        switch (point.station_type) {
            case 'charging':
                if (highlight && point.is_active) return chargingHover
                if (!point.is_active) {
                    return chargingDisabled
                }
                return charging
            case 'docking':
                return docking
            case 'undocking':
                return undocking
            case 'resting':
                if (highlight && point.is_active) return restingHover
                if (!point.is_active) {
                    return restingDisabled
                }
                return resting
            case 'traversal':
                if (highlight) return traversalHover
                if (!point.is_active) {
                    return traversalDisabled
                }
                return traversal
            case 'evacuation':
                if (highlight && point.is_active) return evacuationHover
                if (!point.is_active) {
                    return evacuationDisabled
                }
                return evacuationDefault
            default:
                if (highlight && point.is_active && !point.custom_icon) return defaultHover
                if (!point.is_active) {
                    return stationDisabled
                }
                if (point.custom_icon) {
                    return customStationIcon
                }
                return stationDefault
        }
    }

    
    if (!point) return

    const name = point.title
    const posX = `${point.points_in_meters[0]}m`
    const posY = `${point.points_in_meters[1]}m`
    const type = point.station_type.charAt(0).toUpperCase() + point.station_type.slice(1)
    const rotation = point.rotation
    const dataToShow = { name, type, x: posX, y: posY, rotation }

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={point?.station_dimensions_in_pixels[1]}
                height={point?.station_dimensions_in_pixels[1]}
                draggable={editPoint}
                {...props}
                onDragEnd={(event) => handleDragEnd({ event, pointKey })}
                onDragStart={(e) => handleDragStart(e)}
                offsetX={point?.station_dimensions_in_pixels[1] / 2}
                offsetY={point?.station_dimensions_in_pixels[1] / 2}
                y={point.points[1]}
                x={point.points[0]}
                scaleX={highlight ? 1.1 : 1}
                scaleY={highlight ? 1.1 : 1}
                rotation={point.rotation}
                onMouseEnter={(e) => {
                    if (point.station_type === 'traversal' || point.station_type === 'action')
                        return
                    if (estimatedPose) return
                    sethighlight(true)
                    setDataHovered(dataToShow)
                    const container = e.target.getStage().container()
                    editPoint
                        ? (container.style.cursor = 'move')
                        : (container.style.cursor = 'default')
                    container.style.cursor = 'pointer'
                }}
                onMouseLeave={(e) => {
                    if (point.station_type === 'traversal' || point.station_type === 'action')
                        return
                    sethighlight(false)
                    setDataHovered(null)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                }}
                onClick={(e) => {
                    if (
                        point.station_type === 'traversal' ||
                        point.station_type === 'action' ||
                        localStorage.getItem('nuke') === 'stopped'
                    )
                        return
                    if (!point.is_active) return
                    if (selectedRobot && e.target !== e.target.getStage()) {
                        handlePointSubmitSelect(point.uuid, selectedRobot)
                        return
                    }
                    toggle()
                }}
            />

            <Text
                x={point.points[0]}
                y={point.points[1] - sizeText}
                offsetX={point?.station_dimensions_in_pixels[1] / 2}
                offsetY={point?.station_dimensions_in_pixels[1] / 2}
                stroke="#670d95"
                strokeWidth={0.1}
                text={icon ? point.title : ''}
                fontStyle="bold"
                fontSize={sizeText}
            />

            <Html>
                <Modal isOpen={openPopover} toggle={toggle} centered className="new-modals">
                    <ModalBody>
                        <div
                            style={{ marginBottom: '24px' }}
                            className={`w-100 d-flex justify-content-between`}
                        >
                            <img
                                src="/svgs/task-modal/smiley-robot.svg"
                                alt="plus"
                                width="48px"
                                height="48px"
                            />

                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="x"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        <span className="modals-little-head">Send a robot</span>
                        <br />
                        <span className="modals-description">
                            Send any robot to {point.title} or manually select a robot.
                        </span>

                        <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ marginTop: '36px' }}
                        >
                            <Button
                                style={{ marginRight: '24px' }}
                                color="none"
                                className="modals-delete-btn"
                                onClick={() => {
                                    toggle()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="none"
                                className="modals-cancel-btn modals-new-btns"
                                onClick={() => {
                                    setSelectedStation(point.uuid)
                                    toggle()
                                }}
                            >
                                Select
                            </Button>
                            <Button
                                color="meili"
                                className="save-btn-modal modals-new-btns"
                                onClick={() => {
                                    handleSubmit(point.uuid)
                                    toggle()
                                }}
                            >
                                Any robot
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Html>
        </>
    )
}

export default IndoorMapPoint
