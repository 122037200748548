import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik, FieldArray } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'

import cx from 'classnames'
import {
    createMaintenanceService,
    createVehicle,
    deleteCustomRobotIcon,
    getCustomiseRobotIcon,
    getMaintenanceService,
    getOrgVehiclePage,
    requestFactSheet,
    updateVehicle,
} from 'features/dashboard/Vehicle.actionTypes'
import {
    getCategories,
    getMaintenanceServices,
    getOrganizationTeams,
    getVehicleIcon,
} from 'features/dashboard/selectors'
import {
    Button,
    ButtonGroup,
    Input,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    UncontrolledTooltip,
} from 'reactstrap'
import { customInputForm } from 'ui/common'
import DropdownSelect from 'layout-components/Dropdown'
import CustomStationIcon from '../MapComponents/CustomStationIcon'
import { fileUpload } from 'middleware/api'
import moment from 'moment'

const VehiclesModal = ({
    slug,
    form,
    setForm,
    modal,
    toggle,
    vehicle = {},
    currentPageNumber,
    filters,
}) => {
    const dispatch = useDispatch()
    const teams = useSelector(getOrganizationTeams)
    const categories = useSelector(getCategories)
    const customIcon = useSelector(getVehicleIcon)
    const services = useSelector(getMaintenanceServices)
    const [tab, setTab] = useState('general')
    const [scrollPosition, setScrollPosition] = useState('top')
    const [toggleBattery, setToggleBattery] = useState(false)
    const [team, setTeam] = useState(teams?.find((t) => t.uuid === vehicle.team)?.title || '')
    const [category, setCategory] = useState(vehicle.category_name)
    const [integrationType, setIntegrationType] = useState(vehicle?.integration_type || '')
    const [vehicleType, setVehicleType] = useState(vehicle.vehicle_type?.toUpperCase() || '')
    const [enableFreeRoaming, setEnableFreeRoaming] = useState(vehicle?.free_roaming || '')
    const dischargeValue = parseFloat(vehicle.discharge_per_meter)
    const inputFile = useRef(null)
    const [error, setError] = useState('')
    const [edit, setEdit] = useState('')
    const [image, setImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState('')
    const [fetchBtn, setFetchBtn] = useState(false)
    const [uploadIcon, setUploadIcon] = useState(false)
    const [preview, setPreview] = useState('')
    const iconColor = vehicle?.icon_color ? vehicle?.icon_color : '#BA4FFA'
    const [color, setColor] = useState(iconColor)
    const defaultIcon = '/svgs/map-layer/amr.svg'

    const dateInputRef = useRef(null);
    const triggerDatePicker = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker(); 
    }
  };

    const today = new Date()
    const filterPastDate = services
        .map((item) => ({
            ...item,
            maintenance_date: new Date(item?.maintenance_date),
        }))
        .filter((item) => item?.maintenance_date > today)

    const upcomingService = () => {
        if (filterPastDate.length > 0) {
            const firstMaintenanceDate = filterPastDate.reduce((earliest, current) =>
                current.maintenance_date < earliest.maintenance_date ? current : earliest
            )
            const duration = firstMaintenanceDate.maintenance_advance_notification.map((item) => {
                const days = item.split(' ')[0]
                return `P${days}D`
            })
            return { ...firstMaintenanceDate, maintenance_advance_notification: duration }
        } else return { firstMaintenanceDate: [] }
    }

    const renderCustomIcon = customIcon.map((icon) => {
        let newImageURL = ''

        switch (icon.title) {
            case 'Agv':
                newImageURL = '/svgs/map-layer/agv-manageable.svg'
                break
            case 'Amr':
                newImageURL = '/svgs/map-layer/amr.svg'
                break
            default:
                newImageURL = icon.icon
                break
        }

        return { ...icon, icon: newImageURL }
    })

    const [selectedCustomIcon, setSelectedIcon] = useState(renderCustomIcon)

    const selectedIcon = Object?.assign(
        {},
        ...renderCustomIcon?.filter((icon) => icon.uuid === vehicle?.custom_icon)
    )
    const [icon, setIcon] = useState(selectedIcon)
    const [title, setTitle] = useState('')

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }
    const deleteIcon = (uuid) => {
        dispatch(deleteCustomRobotIcon(uuid))
        const updatedItems = selectedCustomIcon.filter((item) => item.uuid !== uuid)
        setSelectedIcon(updatedItems)
        if (preview?.uuid === uuid) {
            setPreview(updatedItems[0] || null) // Select first remaining item (or null)
        }
    }

    useEffect(() => {
        dispatch(getCustomiseRobotIcon(slug))
    }, [slug, dispatch])

    useEffect(() => {
        const uuid = vehicle.uuid
        dispatch(getMaintenanceService(uuid))
    }, [vehicle, dispatch])

    const handleImageChange = async (event) => {
        const file = event.target.files[0]
        setSelectedFile(file)
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImage(reader.result)
            }
            reader.readAsDataURL(file)
        }
        setFetchBtn(false)
    }

    const onButtonClick = () => {
        inputFile.current.click()
        setFetchBtn(true)
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('title', title)
        if (selectedFile) {
            formData.append('icon', selectedFile)
        }
        await fileUpload
            .patch(`/api/robot-icons/${edit.uuid}`, formData, {
                method: 'PATCH',
                body: formData,
                'Content-Type': 'multipart/form-data',
            })
            .then((result) => {
                setError('')
                setPreview(result.data)
                setUploadIcon(false)
            })
            .catch((err) => {
                setError(err.response.data.errors)
            })
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file to upload.')
            console.log('error')
            return
        }
        const formData = new FormData()
        formData.append('icon', selectedFile)
        formData.append('title', title)
        formData.append('system_generated', true)
        await fileUpload
            .post(`/api/organizations/${slug}/robot-icons/`, formData, {
                method: 'POST',
                body: formData,
                'Content-Type': 'multipart/form-data',
            })

            .then((result) => {
                setError('')
                setPreview(result.data)
                console.log('updated')
                setUploadIcon(false)
            })
            .catch((err) => {
                setError(err.response.data.errors)
            })
    }
    const vehicleTypes = [
        { uuid: 'agv_manageable', name: 'AGV_MANAGEABLE' },
        { uuid: 'amr', name: 'AMR' },
        {
            uuid: 'agv_non_manageable',
            name: 'AGV_NON_MANAGEABLE',
        },
        { uuid: 'rtls', name: 'RTLS' },
        { uuid: 'visualize', name: 'VISUALIZE' },
    ]

    const freeRoamingTypes = [
        { uuid: 'disabled', name: 'Disabled' },
        { uuid: 'aided', name: 'Aided' },
        {
            uuid: 'basic',
            name: 'Basic',
        },
    ]

    const setInitialValues = () => {
        switch (true) {
            case tab === 'battery' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    full_battery: vehicle.full_battery,
                    working_battery_level: vehicle.working_battery_level,
                    emergency_battery_level: vehicle.emergency_battery_level,
                    calculate_battery_discharge: vehicle.calculate_battery_discharge,
                    emergency_distance: vehicle.emergency_distance,
                    discharge_per_meter: vehicle.discharge_per_meter,
                    user_defined_discharge_per_meter: vehicle.user_defined_discharge_per_meter,
                    driveable_distance: vehicle.driveable_distance,
                    range: vehicle.range,
                    use_team_battery_defaults: vehicle.use_team_battery_defaults,
                    battery_management_enabled: vehicle.battery_management_enabled,
                }
            case tab === 'general' && form === 'edit-vehicle':
                return {
                    verbose_name: vehicle.verbose_name,
                    uuid: vehicle.uuid,
                    active: vehicle.active,
                    free_roaming: vehicle.free_roaming,
                    category: vehicle.category,
                    vehicle_type: vehicle.vehicle_type,
                    team: vehicle.team,
                    custom_manufacturer: vehicle.custom_manufacturer,
                    integration_type: vehicle.integration_type,
                    vehicle_serial_number: vehicle.vehicle_serial_number,
                }

            case tab === 'traffic' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    message_frequency: vehicle.message_frequency,
                    traffic_control_delay: vehicle.traffic_control_delay,
                    override_stay_on: vehicle.override_stay_on,
                    activate_vehicle_prediction:
                        vehicle.activate_vehicle_prediction !== null
                            ? vehicle.activate_vehicle_prediction
                            : true,
                }

            case tab === 'dimensions' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    dimensions: vehicle.dimensions,
                }
            case tab === 'map_offsets' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    initial_position: vehicle.initial_position,
                }
            case tab === 'appearance' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    icon_color: vehicle.icon_color,
                    custom_icon: vehicle.custom_icon,
                }

            case tab === 'maintenance' && form === 'edit-vehicle':
                return {
                    uuid: vehicle.uuid,
                    vehicle: vehicle.uuid,
                    maintenance_notifications: filterPastDate.length !== 0 ? true : false,
                    maintenance_date:
                        filterPastDate.length !== 0
                            ? moment(upcomingService()?.maintenance_date).format('YYYY-MM-DD')
                            : '',
                    maintenance_advance_notification:
                        upcomingService()?.maintenance_advance_notification || [],
                }

            default:
                return {
                    vehicle,
                    activate_vehicle_prediction: vehicle.activate_vehicle_prediction
                        ? vehicle.activate_vehicle_prediction
                        : true,
                }
        }
    }

    useEffect(() => {
        if (!vehicle?.emergency_distance) {
            setToggleBattery(true)
        }
    }, [vehicle.emergency_distance])

    const handleBatteryToggle = () => setToggleBattery(!toggleBattery)

    const handleSubmit = (values) => {
        const {
            is_collision_detected,
            uuid,
            driveable_distance,
            range,
            discharge_per_meter,
            user_defined_discharge_per_meter,
            ...data
        } = values

        if (!values.calculate_battery_discharge) {
            data.user_defined_discharge_per_meter = user_defined_discharge_per_meter
        }

        if (form === 'edit-vehicle' && tab === 'maintenance') {
            dispatch(createMaintenanceService({ uuid, ...data })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgVehiclePage({ slug, page: currentPageNumber, filters }))
                    toggle()
                }
            })
        }

        if (form === 'edit-vehicle') {
            dispatch(updateVehicle({ uuid, ...data })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgVehiclePage({ slug, page: currentPageNumber, filters }))
                    toggle()
                }
            })
        }

        if (form === 'add-vehicle') {
            dispatch(createVehicle({ slug, ...data })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgVehiclePage({ slug, page: currentPageNumber, filters }))
                    toggle()
                }
            })
        }
    }

    const renderArrow = () => {
        if (tab === 'general') {
            if (scrollPosition === 'bottom') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-up-arrow-modals"
                    />
                )
            } else if (scrollPosition === 'top') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-down-arrow-modals"
                    />
                )
            } else {
                return (
                    <div className="d-flex">
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-up-arrow-modals"
                        />
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-down-arrow-modals"
                        />
                    </div>
                )
            }
        }
        return <div></div>
    }
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight
        const top = e.target.scrollTop === 0

        if (bottom) {
            setScrollPosition('bottom')
        } else if (top) {
            setScrollPosition('top')
        } else {
            setScrollPosition('middle')
        }
    }

    const handleVehicleCategoryChange = (category, setFieldValue, categoryName) => {
        const selectedCategoryObj = categories.find((cat) => cat.uuid === category)
        setCategory(categoryName)
        setVehicleType(selectedCategoryObj?.vehicle_type?.toUpperCase() || 'AMR')
        setFieldValue('category', category || null)
        setFieldValue('vehicle_type', selectedCategoryObj?.vehicle_type || 'amr')
    }

    const integrationTypes = [
        { uuid: '', name: '' },
        { uuid: 'ROS', name: 'ROS' },
        { uuid: 'MIR', name: 'MiR' },
        { uuid: 'NOOVELIA', name: 'NOOVELIA' },
        { uuid: 'VDA5050', name: 'VDA5050' },
        { uuid: 'Wewo', name: 'Wewo' },
        { uuid: 'Youibot', name: 'Youibot' },
        { uuid: 'ROBOLIGENT', name: 'Roboligent' },
    ]

    const maintenanceOptions = [
        { label: '24h in advance', value: 'P1D' },
        { label: '48h in advance', value: 'P2D' },
        { label: '1 week in advance', value: 'P7D' },
        { label: '2 weeks in advance', value: 'P14D' },
        { label: '1 month in advance', value: 'P30D' },
    ]



    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered size="xl">
                <ModalBody
                    style={{
                        padding: '24px 24px 0px 24px',
                        position: 'relative',
                        overflowY: 'auto',
                    }}
                    onScroll={handleScroll}
                >
                    <div
                        className="w-100 d-flex justify-content-between"
                        style={{ marginBottom: '24px' }}
                    >
                        <img
                            src="/svgs/task-modal/smiley-robot.svg"
                            alt="obstacle-icon"
                            width="48px"
                            height="48px"
                        />
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            width="24px"
                            height="24px"
                            style={{ cursor: 'pointer' }}
                            onClick={toggle}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <span className="org-modal-header">
                            {form === 'add-vehicle' ? 'New vehicle' : 'Vehicle settings'}
                        </span>
                        <div className="modals-container-buttons">
                            <ButtonGroup className="org-button-group">
                                <Button
                                    className={`${
                                        tab === 'general' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('general')
                                    }}
                                >
                                    General
                                </Button>
                                {form === 'edit-vehicle' && (
                                    <Button
                                        className={`${
                                            tab === 'battery' ? 'org-button-selected' : ''
                                        } org-button`}
                                        style={{ borderRadius: '6px' }}
                                        onClick={() => {
                                            setTab('battery')
                                        }}
                                    >
                                        Battery
                                    </Button>
                                )}
                                <Button
                                    className={`${
                                        tab === 'appearance' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('appearance')
                                        setSelectedIcon(renderCustomIcon)
                                    }}
                                >
                                    Appearance
                                </Button>
                                <Button
                                    className={`${
                                        tab === 'maintenance' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('maintenance')
                                        setSelectedIcon(renderCustomIcon)
                                    }}
                                >
                                    Maintenance
                                </Button>

                                <Button
                                    className={`${
                                        tab === 'traffic' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('traffic')
                                    }}
                                >
                                    Traffic control
                                </Button>
                                <Button
                                    className={`${
                                        tab === 'dimensions' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('dimensions')
                                    }}
                                >
                                    Dimensions
                                </Button>
                                <Button
                                    className={`${
                                        tab === 'map_offsets' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ borderRadius: '6px' }}
                                    onClick={() => {
                                        setTab('map_offsets')
                                    }}
                                >
                                    Map offsets
                                </Button>
                                {vehicle.uuid && vehicle.integration_type === 'VDA5050' && (
                                    <Button
                                        className={`${
                                            tab === 'factsheet' ? 'org-button-selected' : ''
                                        } org-button`}
                                        style={{ borderRadius: '6px' }}
                                        onClick={() => {
                                            setTab('factsheet')
                                        }}
                                    >
                                        Factsheet
                                    </Button>
                                )}
                            </ButtonGroup>
                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={setInitialValues()}
                        onSubmit={handleSubmit}
                                
                    >
                        {({ values, setFieldValue, handleSubmit }) => (
                            <Form
                                enableReinitialize
                                style={{ marginTop: '14px' }}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleSubmit()
                                }}
                            >
                                {tab === 'general' && (
                                    <>
                                        <FormGroup>
                                            <Label className="modals-labels" for="verbose_name">
                                                Name
                                            </Label>
                                            <Field
                                                className="modals-inputs"
                                                component={customInputForm}
                                                type="text"
                                                value={values.verbose_name || ''}
                                                name="verbose_name"
                                                placeholder="Name"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels" for="team">
                                                Team *
                                            </Label>
                                            <DropdownSelect
                                                selected={team}
                                                setSelected={setTeam}
                                                options={teams.map((team) => team)}
                                                setFieldValue={setFieldValue}
                                                fieldValue="team"
                                            />
                                            {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="select"
                                            required
                                            value={values.team || ''}
                                            name="team"
                                        >
                                            <option value=""></option>
                                            {teams.map((team) => (
                                                <option value={team.uuid} key={team.uuid}>
                                                    {team.title}
                                                </option>
                                            ))}
                                        </Field> */}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels" for="category">
                                                Category
                                            </Label>
                                            <DropdownSelect
                                                selected={category}
                                                setSelected={setCategory}
                                                options={[' ', ...categories.map((cat) => cat)]}
                                                setFieldValue={setFieldValue}
                                                onClick={handleVehicleCategoryChange}
                                                fieldValue="category"
                                            />
                                            {/* <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="select"
                                            name="category"
                                            value={values.category || ''}
                                            onChange={(e) =>
                                                handleVehicleCategoryChange(e, setFieldValue)
                                            }
                                        >
                                            <option value=""></option>
                                            {categories.map((cat) => (
                                                <option value={cat.uuid} key={cat.uuid}>
                                                    {cat.title}
                                                </option>
                                            ))}
                                        </Field> */}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for="custom_manufacturer"
                                            >
                                                Custom manufacturer
                                            </Label>
                                            <Field
                                                className="modals-inputs"
                                                component={customInputForm}
                                                type="text"
                                                value={values.custom_manufacturer || ''}
                                                name="custom_manufacturer"
                                                placeholder="802540e178b14cd484b624784c51da93"
                                                pattern="^[^\s]+$"
                                            />
                                            <small>
                                                Enter a custom manufacturer without spaces.
                                            </small>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels" for="integration_type">
                                                Integration type
                                            </Label>
                                            <DropdownSelect
                                                selected={integrationType}
                                                setSelected={setIntegrationType}
                                                options={integrationTypes.map((type) => type)}
                                                setFieldValue={setFieldValue}
                                                fieldValue="integration_type"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels" for="vehicle_type">
                                                Vehicle type *
                                            </Label>
                                            <img
                                                src="/svgs/trigger-actions/question-mark.svg"
                                                alt="plus"
                                                id="type_info"
                                                width={16}
                                                height={16}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#C2C5CE',
                                                    marginLeft: '4px',
                                                }}
                                            />
                                            <UncontrolledTooltip
                                                placement="left"
                                                target="type_info"
                                                style={{
                                                    maxWidth: '20rem',
                                                    background: 'white',
                                                    textAlign: 'start',
                                                    borderRadius: '1rem',
                                                    whiteSpace: 'break-spaces',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                -AGV_MANAGEABLE: Automated Guided Vehicles that can
                                                execute missions managed by Meili. <br />
                                                -AMR: Autonomous Mobile Robots. <br />
                                                -AGV_NON_MANAGEABLE: AGVs that cannot execute
                                                missions sent by Meili FMS but are tracked by
                                                Traffic Control.
                                                <br />
                                                -RTLS: Real-time Location System that cannot execute
                                                missions sent by Meili FMS but is tracked by Traffic
                                                Control. <br />
                                                -VISUALIZE: Neither capable of running missions nor
                                                tracked by Traffic Control.
                                            </UncontrolledTooltip>
                                            <DropdownSelect
                                                selected={vehicleType}
                                                setSelected={setVehicleType}
                                                options={vehicleTypes}
                                                setFieldValue={setFieldValue}
                                                fieldValue="vehicle_type"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for="vehicle_serial_number"
                                            >
                                                Vehicle serial number
                                            </Label>
                                            <Field
                                                className="modals-inputs"
                                                component={customInputForm}
                                                type="text"
                                                placeholder="802540e178b14cd484b624784c51da93"
                                                value={values.vehicle_serial_number || ''}
                                                name="vehicle_serial_number"
                                                pattern="^[^\s]+$"
                                            />
                                            <small>Enter a serial number without spaces.</small>
                                        </FormGroup>
                                        {/* <FormGroup>
                                        <Label className="modals-labels">
                                            <CustomInput
                                                type="checkbox"
                                                id="batch_tasks"
                                                label="Batch Tasks"
                                                checked={values?.batch_tasks}
                                                onChange={() => {
                                                    setFieldValue(
                                                        'batch_tasks',
                                                        !values.batch_tasks
                                                    )
                                                }}
                                            />
                                        </Label>
                                        
                                    </FormGroup> */}
                                        <FormGroup>
                                            <Label className="modals-labels" for="vehicle_type">
                                                Free roaming
                                            </Label>
                                            <img
                                                src="/svgs/trigger-actions/question-mark.svg"
                                                alt="plus"
                                                id="type_info_roaming"
                                                width={16}
                                                height={16}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#C2C5CE',
                                                    marginLeft: '4px',
                                                }}
                                            />
                                            <UncontrolledTooltip
                                                placement="left"
                                                target="type_info_roaming"
                                                style={{
                                                    maxWidth: '20rem',
                                                    background: 'white',
                                                    textAlign: 'start',
                                                    borderRadius: '1rem',
                                                    whiteSpace: 'break-spaces',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                Enables robots to execute tasks even if two station
                                                are not connected with path
                                            </UncontrolledTooltip>
                                            <DropdownSelect
                                                selected={enableFreeRoaming}
                                                setSelected={setEnableFreeRoaming}
                                                options={freeRoamingTypes}
                                                setFieldValue={setFieldValue}
                                                fieldValue="free_roaming"
                                            />
                                        </FormGroup>
                                        <FormGroup className="ml-2" check>
                                            <Input
                                                type="checkbox"
                                                id="vehicle_enabled"
                                                checked={values.active}
                                                onChange={() => {
                                                    setFieldValue('active', !values.active)
                                                }}
                                            />
                                            <Label className="modals-labels">Active</Label>
                                        </FormGroup>
                                    </>
                                )}
                                {tab === 'battery' && form === 'edit-vehicle' && (
                                    <>
                                        <div
                                            className="d-flex justify-content-start align-items-center"
                                            style={{ margin: '12px 0px' }}
                                        >
                                            <FormGroup className="mr-4" style={{ width: '220px' }}>
                                                <Label className="modals-labels">
                                                    Full battery
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        className="modals-inputs"
                                                        component={customInputForm}
                                                        type="number"
                                                        value={values?.full_battery}
                                                        style={{ paddingRight: '2rem' }}
                                                        name="full_battery"
                                                        placeholder="Full battery"
                                                    />
                                                    <span style={{ marginLeft: '-1.5rem' }}>%</span>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="ml-1" style={{ width: '220px' }}>
                                                <Label className="modals-labels">
                                                    Working battery level
                                                </Label>
                                                <img
                                                    src="/svgs/trigger-actions/question-mark.svg"
                                                    alt="plus"
                                                    id="working-b-l"
                                                    width={16}
                                                    height={16}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#C2C5CE',
                                                        marginLeft: '4px',
                                                    }}
                                                />

                                                <UncontrolledTooltip
                                                    placement="left"
                                                    target="working-b-l"
                                                    delay={{ show: 100, hide: 400 }}
                                                    className="custom-tooltip"
                                                >
                                                    The level at which the robot is released from
                                                    the charging station and becomes available for a
                                                    task.
                                                </UncontrolledTooltip>

                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        className="modals-inputs"
                                                        component={customInputForm}
                                                        type="number"
                                                        style={{ paddingRight: '2rem' }}
                                                        value={values?.working_battery_level}
                                                        name="working_battery_level"
                                                        placeholder="Working battery level"
                                                    />
                                                    <span style={{ marginLeft: '-1.5rem' }}>%</span>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <FormGroup>
                                            <div className="d-flex">
                                                <FormGroup
                                                    switch
                                                    style={{ margin: '0px 0px 12px 12px' }}
                                                >
                                                    <Input
                                                        style={{ height: '20px', width: '36px' }}
                                                        role="switch"
                                                        type="switch"
                                                        className="custom-switch-form modals-labels"
                                                        id="emergency_battery_level"
                                                        onChange={() => {
                                                            if (!toggleBattery) {
                                                                setFieldValue(
                                                                    'emergency_distance',
                                                                    ''
                                                                )
                                                            } else {
                                                                setFieldValue(
                                                                    'emergency_battery_level',
                                                                    ''
                                                                )
                                                            }
                                                            handleBatteryToggle()
                                                        }}
                                                        checked={toggleBattery}
                                                    />
                                                    <Label
                                                        check
                                                        className="mt-1 ml-2 modals-labels"
                                                    >
                                                        Emergency battery level{' '}
                                                    </Label>
                                                </FormGroup>

                                                <img
                                                    src="/svgs/trigger-actions/question-mark.svg"
                                                    alt="plus"
                                                    id="emergency-b-l"
                                                    width={16}
                                                    height={16}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#C2C5CE',
                                                        marginLeft: '4px',
                                                    }}
                                                />
                                                <UncontrolledTooltip
                                                    placement="left"
                                                    target="emergency-b-l"
                                                    delay={{ show: 100, hide: 400 }}
                                                    className="custom-tooltip"
                                                >
                                                    The level at which the robot is sent to a
                                                    charging station.
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    component={customInputForm}
                                                    type="number"
                                                    step="any"
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2rem' }}
                                                    disabled={
                                                        values?.use_team_battery_defaults ===
                                                            true || toggleBattery === false
                                                    }
                                                    value={values.emergency_battery_level || ''}
                                                    name="emergency_battery_level"
                                                />
                                                <span style={{ marginLeft: '-1.5rem' }}>%</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="d-flex">
                                                <Label className="modals-labels">
                                                    Emergency distance
                                                </Label>

                                                <img
                                                    src="/svgs/trigger-actions/question-mark.svg"
                                                    alt="plus"
                                                    id="emergency-d"
                                                    width={16}
                                                    height={16}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#C2C5CE',
                                                        marginLeft: '4px',
                                                    }}
                                                />
                                                <UncontrolledTooltip
                                                    placement="left"
                                                    target="emergency-d"
                                                    delay={{ show: 100, hide: 400 }}
                                                    className="custom-tooltip"
                                                >
                                                    The distance that can be covered with the
                                                    emergency battery level.
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2rem' }}
                                                    type="number"
                                                    step="any"
                                                    disabled={
                                                        values?.use_team_battery_defaults ===
                                                            true || toggleBattery === true
                                                    }
                                                    value={values.emergency_distance || ''}
                                                    name="emergency_distance"
                                                />
                                                <span style={{ marginLeft: '-1.5rem' }}>m</span>
                                            </div>
                                            <small>
                                                Note: Cannot set emergency battery level and
                                                emergency distance simultaneously
                                            </small>
                                        </FormGroup>

                                        <FormGroup switch style={{ margin: '24px 0px 12px 12px' }}>
                                            <Input
                                                style={{ height: '20px', width: '36px' }}
                                                role="switch"
                                                type="switch"
                                                className="custom-switch-form modals-labels"
                                                id="calculate_battery_discharge"
                                                onChange={() => {
                                                    setFieldValue(
                                                        'calculate_battery_discharge',
                                                        !values.calculate_battery_discharge
                                                    )
                                                    setFieldValue(
                                                        'user_defined_discharge_per_meter',
                                                        values.user_defined_discharge_per_meter || 0
                                                    )
                                                    setFieldValue(
                                                        'discharge_per_meter',
                                                        dischargeValue
                                                    )
                                                }}
                                                checked={values.calculate_battery_discharge}
                                            />
                                            <Label check className="mt-1 ml-2 modals-labels">
                                                Calculate battery discharge
                                            </Label>
                                        </FormGroup>

                                        {values.calculate_battery_discharge ? (
                                            <FormGroup>
                                                <div className="d-flex">
                                                    <Label className="modals-labels">
                                                        Discharge per meter
                                                    </Label>

                                                    <img
                                                        src="/svgs/trigger-actions/question-mark.svg"
                                                        alt="plus"
                                                        id="discharge_per_meter"
                                                        width={16}
                                                        height={16}
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#C2C5CE',
                                                            marginLeft: '4px',
                                                        }}
                                                    />
                                                    <UncontrolledTooltip
                                                        placement="left"
                                                        target="discharge_per_meter"
                                                        delay={{ show: 100, hide: 400 }}
                                                        className="custom-tooltip"
                                                    >
                                                        It is the percentage of the battery that is
                                                        discharged when the vehicle covers a
                                                        distance of one meter. This value is
                                                        automatically calculated by Meili FMS during
                                                        the execution of missions→ Discharged
                                                        battery percentage in a meter distance.
                                                    </UncontrolledTooltip>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        component={customInputForm}
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2rem' }}
                                                        type="number"
                                                        step="any"
                                                        value={dischargeValue}
                                                        name="discharge_per_meter"
                                                        disabled
                                                    />
                                                    <span style={{ marginLeft: '-1.8rem' }}>%</span>
                                                </div>
                                            </FormGroup>
                                        ) : (
                                            <FormGroup>
                                                <div className="d-flex">
                                                    <Label className="modals-labels">
                                                        User defined discharge per meter
                                                    </Label>

                                                    <img
                                                        src="/svgs/trigger-actions/question-mark.svg"
                                                        alt="plus"
                                                        id="user_defined_discharge_per_meter"
                                                        width={16}
                                                        height={16}
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#C2C5CE',
                                                            marginLeft: '4px',
                                                        }}
                                                    />
                                                    <UncontrolledTooltip
                                                        placement="left"
                                                        target="user_defined_discharge_per_meter"
                                                        delay={{ show: 100, hide: 400 }}
                                                        className="custom-tooltip"
                                                    >
                                                        Percentage of the battery that is discharged
                                                        when the vehicle covers a distance of one
                                                        meter. Default is 0.05%.
                                                    </UncontrolledTooltip>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        component={customInputForm}
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2rem' }}
                                                        type="number"
                                                        step="any"
                                                        value={
                                                            values.user_defined_discharge_per_meter
                                                        }
                                                        name="user_defined_discharge_per_meter"
                                                    />
                                                    <span style={{ marginLeft: '-1.8rem' }}>%</span>
                                                </div>
                                            </FormGroup>
                                        )}

                                        <FormGroup>
                                            <div className="d-flex">
                                                <Label className="modals-labels">
                                                    Driveable distance
                                                </Label>

                                                <img
                                                    src="/svgs/trigger-actions/question-mark.svg"
                                                    alt="plus"
                                                    id="driveable_distance"
                                                    width={16}
                                                    height={16}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#C2C5CE',
                                                        marginLeft: '4px',
                                                    }}
                                                />
                                                <UncontrolledTooltip
                                                    placement="left"
                                                    target="driveable_distance"
                                                    delay={{ show: 100, hide: 400 }}
                                                    className="custom-tooltip"
                                                >
                                                    Total distance that the vehicle could travel
                                                    with the battery at 100% of its capacity.
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="number"
                                                    step="any"
                                                    disabled
                                                    value={values.driveable_distance | ''}
                                                    name="driveable_distance"
                                                />
                                                <span style={{ marginLeft: '-1.8rem' }}>m</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="d-flex">
                                                <Label className="modals-labels">Range:</Label>
                                                <img
                                                    src="/svgs/trigger-actions/question-mark.svg"
                                                    alt="plus"
                                                    id="range"
                                                    width={16}
                                                    height={16}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#C2C5CE',
                                                        marginLeft: '4px',
                                                    }}
                                                />
                                                <UncontrolledTooltip
                                                    placement="left"
                                                    target="range"
                                                    delay={{ show: 100, hide: 400 }}
                                                    className="custom-tooltip"
                                                >
                                                    Total distance the vehicle can travel with the
                                                    current battery level.
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    component={customInputForm}
                                                    className="modals-inputs"
                                                    type="number"
                                                    step="any"
                                                    value={values.range | ''}
                                                    name="range"
                                                    disabled
                                                />
                                                <span style={{ marginLeft: '-1.8rem' }}>m</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup switch style={{ margin: '24px 0px 12px 12px' }}>
                                            <Input
                                                style={{ height: '20px', width: '36px' }}
                                                role="switch"
                                                type="switch"
                                                id="use_team_battery_defaults"
                                                checked={values.use_team_battery_defaults}
                                                name="use_team_battery_defaults"
                                                onChange={() => {
                                                    setFieldValue(
                                                        'use_team_battery_defaults',
                                                        !values.use_team_battery_defaults
                                                    )
                                                }}
                                                className="custom-switch-form modals-labels"
                                            />
                                            <Label check className="mt-1 ml-2">
                                                Use team default settings{' '}
                                            </Label>
                                        </FormGroup>
                                        <FormGroup switch style={{ margin: '12px 0px 24px 12px' }}>
                                            <Input
                                                style={{ height: '20px', width: '36px' }}
                                                role="switch"
                                                type="switch"
                                                id="battery_management_enabled"
                                                checked={values.battery_management_enabled}
                                                name="battery_management_enabled"
                                                onChange={() => {
                                                    setFieldValue(
                                                        'battery_management_enabled',
                                                        !values.battery_management_enabled
                                                    )
                                                }}
                                                className="custom-switch-form modals-labels"
                                            />
                                            <Label check className="mt-cu1 ml-2">
                                                {' '}
                                                Enable battery management
                                            </Label>
                                        </FormGroup>
                                    </>
                                )}
                                {tab === 'appearance' && (
                                    <>
                                        {(icon?.system_generated ||
                                            icon?.title === undefined ||
                                            null) && (
                                            <div>
                                                <FormGroup>
                                                    <Label className="grey-900 weight-500 size-14">
                                                        Vehicle colour
                                                    </Label>
                                                    <label className="color-selector modals-inputs">
                                                        <span
                                                            className="circle"
                                                            style={{ background: color }}
                                                        />
                                                        <span className="grey-400">{color}</span>
                                                        <Field
                                                            style={{
                                                                marginLeft: '100px',
                                                            }}
                                                            type="color"
                                                            id="icon_color"
                                                            name="icon_color"
                                                            value={values.icon_color || ''}
                                                            onChange={(e) => {
                                                                const color = e.target.value
                                                                setColor(color)
                                                                setFieldValue('icon_color', color)
                                                            }}
                                                            className="hidden"
                                                        />
                                                    </label>
                                                </FormGroup>
                                                <div className="d-flex">
                                                    <FormGroup
                                                        switch
                                                        className="d-flex flex-column mb-2 ml-3 mb-3"
                                                    >
                                                        <Input
                                                            style={{
                                                                height: '20px',
                                                                width: '36px',
                                                            }}
                                                            role="switch"
                                                            type="switch"
                                                            id="apply_color_choice_to"
                                                            className="custom-switch-form modals-labels"
                                                            checked={
                                                                values.apply_color_choice_to || ''
                                                            }
                                                            name="apply_color_choice_to"
                                                            onChange={(e) => {
                                                                setFieldValue(e.target.name, 'all')
                                                            }}
                                                        />
                                                        <Label
                                                            check
                                                            className="mt-1"
                                                            style={{ marginLeft: '8px' }}
                                                        >
                                                            Apply colour to all vehicles
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        )}

                                        {!uploadIcon && (
                                            <div className="mt-3 mb-3">
                                                <FormGroup>
                                                    <Label className="grey-900 weight-500 mb-3 size-14">
                                                        Vehicle icon
                                                    </Label>

                                                    <CustomStationIcon
                                                        selected={icon}
                                                        preview={preview}
                                                        setPreview={setPreview}
                                                        setSelected={setIcon}
                                                        options={['', ...selectedCustomIcon]}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue="custom_icon"
                                                        iconColor={color}
                                                        defaultIcon={defaultIcon}
                                                        deleteIcon={deleteIcon}
                                                    />
                                                </FormGroup>
                                                <div className="d-flex">
                                                    <FormGroup
                                                        switch
                                                        className="d-flex flex-column mb-2 ml-3"
                                                    >
                                                        <Input
                                                            style={{
                                                                height: '20px',
                                                                width: '36px',
                                                            }}
                                                            role="switch"
                                                            type="switch"
                                                            id="apply_custom_icon_choice_to"
                                                            className="custom-switch-form modals-labels"
                                                            checked={
                                                                values.apply_custom_icon_choice_to ||
                                                                ''
                                                            }
                                                            name="apply_custom_icon_choice_to"
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    e.target.name,
                                                                    e.target.checked ? 'all' : null
                                                                )
                                                            }}
                                                        />
                                                        <Label
                                                            check
                                                            className="mt-1"
                                                            style={{ marginLeft: '8px' }}
                                                        >
                                                            Apply icon to all vehicles
                                                        </Label>
                                                    </FormGroup>
                                                </div>

                                                {preview && (
                                                    <div
                                                        className="w-full d-flex ml-1 justify-content-between"
                                                        style={{ marginTop: '30px' }}
                                                    >
                                                        <div className="d-flex justify-content-around">
                                                            <img
                                                                src={preview.icon}
                                                                alt="Selected"
                                                                style={{
                                                                    maxWidth: '20px',
                                                                    height: '20px',
                                                                    marginLeft: '8px',
                                                                }}
                                                            />

                                                            <p className="grey-900 weight-500 size-14 ml-4">
                                                                {preview.title}
                                                            </p>
                                                        </div>
                                                        <div className="d-flex">
                                                            <img
                                                                src="/svgs/map-layer/upload-icon.svg"
                                                                alt="upload-icon"
                                                                width="20rem"
                                                                height="20rem"
                                                                style={{
                                                                    marginRight: '8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setTitle(preview.title)
                                                                    setUploadIcon(true)
                                                                    setEdit(preview)
                                                                    setFetchBtn(false)
                                                                }}
                                                            />
                                                            <HiOutlineTrash
                                                                size={20}
                                                                className="mr-1"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => setPreview(false)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {preview && (
                                            <div className="d-flex">
                                                <FormGroup
                                                    switch
                                                    className="d-flex flex-column mb-2 ml-3"
                                                >
                                                    <Input
                                                        style={{ height: '20px', width: '36px' }}
                                                        role="switch"
                                                        type="switch"
                                                        id="apply_custom_icon_choice_to"
                                                        className="custom-switch-form modals-labels"
                                                        checked={
                                                            values.apply_custom_icon_choice_to || ''
                                                        }
                                                        name="apply_custom_icon_choice_to"
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                e.target.name,
                                                                e.target.checked ? 'all' : null
                                                            )
                                                        }}
                                                    />
                                                    <Label
                                                        check
                                                        className="mt-1"
                                                        style={{ marginLeft: '8px' }}
                                                    >
                                                        Apply to all stations
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        )}

                                        {!uploadIcon && !preview && (
                                            <div className="w-full d-flex mb-3 mt-4 justify-content-center">
                                                <Button
                                                    className="modals-save-btn"
                                                    style={{
                                                        background: '#F9F5FF',
                                                        border: 'none',
                                                        width: '100%',
                                                    }}
                                                    onClick={() => {
                                                        setUploadIcon(true)
                                                        setFetchBtn(true)
                                                    }}
                                                >
                                                    <img
                                                        src="/svgs/task-modal/plus.svg"
                                                        alt="obstacle-icon"
                                                        width="20rem"
                                                        height="20rem"
                                                        style={{ marginRight: '8px' }}
                                                    />
                                                    Upload your own
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                )}

                                {tab === 'maintenance' && form === 'edit-vehicle' && (
                                    <div>
                                        <FormGroup>
                                            <Label className="modals-labels" for="maintenance date">
                                                Next maintenance time
                                            </Label>
                                            <Field
                                                component={customInputForm}
                                                type="date"
                                                id="maintenance_date"
                                                name="maintenance_date"
                                                placeholder="DD/MM/YYYY"
                                                innerRef={dateInputRef}
                                                onClick={triggerDatePicker} 
                                                className="date-model modals-inputs"
                                                value={values.maintenance_date || ''}
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            switch
                                            className="d-flex flex-column mb-2 ml-3 my-3"
                                        >
                                            <Input
                                                style={{ height: '20px', width: '36px' }}
                                                role="switch"
                                                type="switch"
                                                id="maintenance_notifications"
                                                checked={values.maintenance_notifications}
                                                name="maintenance_notifications"
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        e.target.name,
                                                        !values.maintenance_notifications
                                                    )
                                                }}
                                                className="custom-switch-form modals-labels"
                                            />
                                            <Label check className="ml-3 mt-1">
                                                Maintenance notifications
                                            </Label>
                                        </FormGroup>
                                        {values.maintenance_notifications && (
                                            <FieldArray name="maintenance_advance_notification">
                                                {({ push, remove }) => (
                                                    <>
                                                        {maintenanceOptions.map((option) => (
                                                            <div
                                                                key={option.value}
                                                                className="ml-4"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className='form-check-input'
                                                                    checked={values.maintenance_advance_notification.includes(
                                                                        option.value
                                                                    )}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            push(option.value)
                                                                        } else {
                                                                            const index =
                                                                                values.maintenance_advance_notification.indexOf(
                                                                                    option.value
                                                                                )
                                                                            remove(index)
                                                                        }
                                                                    }}
                                                                />
                                                                <Label className="ml-2 modals-labels">
                                                                    {option.label}
                                                                </Label>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </FieldArray>
                                        )}
                                    </div>
                                )}

                                {tab === 'traffic' && (
                                    <div className="d-flex flex-column justify-content-start align-items-start">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <FormGroup className="mr-4" style={{ width: '220px' }}>
                                                <Label className="modals-labels">
                                                    Message frequency
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2rem' }}
                                                        component={customInputForm}
                                                        disabled={
                                                            !values.activate_vehicle_prediction
                                                        }
                                                        type="number"
                                                        step="0.001"
                                                        value={values?.message_frequency || ''}
                                                        name="message_frequency"
                                                        placeholder="Message frequency"
                                                    />
                                                    <span style={{ marginLeft: '-1.5rem' }}>
                                                        Hz
                                                    </span>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="ml-1" style={{ width: '220px' }}>
                                                <Label className="modals-labels">
                                                    Traffic control delay
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2.2rem' }}
                                                        component={customInputForm}
                                                        disabled={
                                                            !values.activate_vehicle_prediction
                                                        }
                                                        type="number"
                                                        step="0.001"
                                                        value={values?.traffic_control_delay || ''}
                                                        name="traffic_control_delay"
                                                        placeholder="Traffic control delay"
                                                    />
                                                    <span style={{ marginLeft: '-2rem' }}>Sec</span>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <FormGroup className="ml-2" check>
                                            <Input
                                                type="checkbox"
                                                id="override_stay_on"
                                                checked={values.override_stay_on}
                                                onChange={() => {
                                                    setFieldValue(
                                                        'override_stay_on',
                                                        !values.override_stay_on
                                                    )
                                                }}
                                            />
                                            <Label className="modals-labels">
                                                Override stay on
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className="ml-2" check>
                                            <Input
                                                type="checkbox"
                                                id="activate_vehicle_prediction"
                                                className='form-check-input'
                                                checked={values.activate_vehicle_prediction}
                                                onChange={() => {
                                                    setFieldValue(
                                                        'activate_vehicle_prediction',
                                                        !values.activate_vehicle_prediction
                                                    )
                                                }}
                                            />
                                            <Label className="modals-labels">
                                                Activate vehicle prediction
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )}

                                {tab === 'dimensions' && (
                                    <>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <FormGroup>
                                                <Label className="modals-labels">Length</Label>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2.4rem' }}
                                                        component={customInputForm}
                                                        type="number"
                                                        value={values?.dimensions?.[0] || ''}
                                                        name="dimensions[0]"
                                                        placeholder="X"
                                                    />
                                                    <span style={{ marginLeft: '-2.2rem' }}>
                                                        mm
                                                    </span>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="mx-4">
                                                <Label className="modals-labels ml-2">Width</Label>
                                                <div className="d-flex align-items-center mx-2">
                                                    <Field
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2.4rem' }}
                                                        component={customInputForm}
                                                        type="number"
                                                        value={values?.dimensions?.[1] || ''}
                                                        name="dimensions[1]"
                                                        placeholder="Y"
                                                    />
                                                    <span style={{ marginLeft: '-2.2rem' }}>
                                                        mm
                                                    </span>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="modals-labels">Height</Label>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        className="modals-inputs"
                                                        style={{ paddingRight: '2.4rem' }}
                                                        component={customInputForm}
                                                        type="number"
                                                        value={values?.dimensions?.[2] || ''}
                                                        name="dimensions[2]"
                                                        placeholder="Z"
                                                    />
                                                    <span style={{ marginLeft: '-2.2rem' }}>
                                                        mm
                                                    </span>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </>
                                )}
                                {tab === 'map_offsets' && (
                                    <div className="d-flex justify-content-start align-items-center">
                                        <FormGroup className="mr-4">
                                            <Label className="modals-labels">Length</Label>
                                            <div className="d-flex align-items-center">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.initial_position?.[0] || ''}
                                                    name="initial_position[0]"
                                                    placeholder="X"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels ml-2">Width</Label>
                                            <div className="d-flex align-items-center mx-2">
                                                <Field
                                                    className="modals-inputs"
                                                    style={{ paddingRight: '2.4rem' }}
                                                    component={customInputForm}
                                                    type="number"
                                                    value={values?.initial_position?.[1] || ''}
                                                    name="initial_position[1]"
                                                    placeholder="Y"
                                                />
                                                <span style={{ marginLeft: '-2.2rem' }}>mm</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                )}
                                {tab === 'factsheet' && (
                                    <div className="w-100 h-100 py-2 px-1">
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            onClick={() => dispatch(requestFactSheet(vehicle))}
                                        >
                                            Request factsheet
                                        </Button>
                                    </div>
                                )}
                                <div
                                    className={cx('new-modal-footer', {
                                        'justify-content-end': tab === 'maintenance',
                                    })}
                                >
                                    {form === 'add-vehicle' ? (
                                        renderArrow()
                                    ) : tab !== 'appearance' ? (
                                        <Button
                                            color="none"
                                            onClick={() => {
                                                setForm('remove-vehicle')
                                            }}
                                            className={cx('modals-delete-btn', {
                                                'hide-delete': tab === 'maintenance',
                                            })}
                                        >
                                            <HiOutlineTrash size={20} className="mr-1" />
                                            Delete
                                        </Button>
                                    ) : (
                                        <Button
                                            className="reset-button modals-delete-btn"
                                            color="none"
                                            onClick={() => {
                                                setColor('#BA4FFA')
                                                setFieldValue('icon_color', '#BA4FFA')
                                                setFieldValue('custom_icon', null)
                                                setIcon(null)
                                                setPreview(false)
                                            }}
                                        >
                                            <img
                                                src="/svgs/map-layer/reset-btn.svg"
                                                alt="reset-icon"
                                                width="21px"
                                                height="21px"
                                            />

                                            <span style={{ marginLeft: '1px' }}>
                                                {' '}
                                                Reset to default
                                            </span>
                                        </Button>
                                    )}
                                    <div className="d-flex">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            onClick={() => {
                                                toggle()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{ marginLeft: '12px' }}
                                            type="submit"
                                            disabled={
                                                form === 'edit-vehicle' &&
                                                JSON.stringify(values) === JSON.stringify(vehicle)
                                            }
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>

            <Modal isOpen={uploadIcon} toggle={setUploadIcon} centered>
                <ModalBody style={{ padding: '24px' }}>
                    <div>
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img
                                src="/svgs/modal-icons/modal-station-icon.svg"
                                alt="obstacle-icon"
                                width="48px"
                                height="48px"
                            />
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setUploadIcon(false)
                                }}
                            />
                        </div>

                        <span className="org-modal-header mb-3">Custom station icon</span>

                        {image && !fetchBtn && (
                            <>
                                <div className="w-full d-flex mt-5 justify-content-between">
                                    <div className="d-flex justify-content-around">
                                        <img
                                            src={image}
                                            alt="Selected"
                                            style={{
                                                maxWidth: '20px',
                                                height: '20px',
                                                marginLeft: '8px',
                                            }}
                                        />

                                        <p className="grey-900 weight-500 size-14 ml-3">{title}</p>
                                    </div>

                                    <div className="d-flex">
                                        <img
                                            src="/svgs/map-layer/upload-icon.svg"
                                            alt="upload-icon"
                                            width="20rem"
                                            height="20rem"
                                            style={{ marginRight: '8px' }}
                                            onClick={() => {
                                                setTitle(preview.title)
                                                setUploadIcon(true)
                                                setEdit(preview)
                                                setFetchBtn(true)
                                            }}
                                        />
                                    </div>
                                </div>
                                {error.icon && <div className="error-message">{error.icon}</div>}
                            </>
                        )}

                        {edit && !image && !fetchBtn && (
                            <>
                                <div className="w-full d-flex mt-5 justify-content-between">
                                    <div className="d-flex justify-content-around">
                                        <img
                                            src={edit.icon}
                                            alt="Selected"
                                            style={{
                                                maxWidth: '20px',
                                                height: '20px',
                                                marginLeft: '8px',
                                                cursor: 'pointer',
                                            }}
                                        />

                                        <p className="grey-900 weight-500 size-14 ml-3">
                                            {edit.title}
                                        </p>
                                    </div>

                                    <div className="d-flex">
                                        <img
                                            src="/svgs/map-layer/upload-icon.svg"
                                            alt="upload-icon"
                                            width="20rem"
                                            height="20rem"
                                            style={{ marginRight: '8px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setFetchBtn(true)
                                            }}
                                        />
                                    </div>
                                </div>
                                {error.icon && <div className="error-message">{error.icon}</div>}
                            </>
                        )}

                        <div className="w-full mt-3">
                            <Label className="modals-labels grey-900 weight-500 size-14 mb-1">
                                Icon name *
                            </Label>
                            <input
                                Required
                                type="text"
                                name="customTitle"
                                className="modals-inputs w-100 "
                                placeholder={title}
                                component={customInputForm}
                                onChange={handleTitleChange}
                                value={title}
                            ></input>
                            {error.title && (
                                <div className="error-message">{error.title.title}</div>
                            )}
                        </div>

                        <div className="w-full d-flex justify-content-between mt-4 mb-3">
                            <input
                                className="d-none"
                                ref={inputFile}
                                onChange={handleImageChange}
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                            />
                            {fetchBtn && (
                                <Button
                                    id="browse-btn"
                                    onClick={onButtonClick}
                                    className="modals-save-btn w-100"
                                    style={{
                                        background: '#F9F5FF',
                                        border: 'none',
                                        width: '100%',
                                    }}
                                >
                                    <img
                                        src="/svgs/task-modal/plus.svg"
                                        alt="obstacle-icon"
                                        width="20rem"
                                        height="20rem"
                                        style={{ marginRight: '8px' }}
                                    />{' '}
                                    Choose file
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            color="none"
                            onClick={() => {
                                setUploadIcon(false)
                            }}
                            className="cancel-btn-modal modals-new-btns mr-2"
                        >
                            Back
                        </Button>
                        {edit ? (
                            <Button
                                className="save-btn-modal modals-new-btns"
                                onClick={(e) => {
                                    handleUpdate(e)
                                }}
                                disabled={!title}
                            >
                                Update
                            </Button>
                        ) : (
                            <Button
                                className="save-btn-modal modals-new-btns"
                                onClick={() => {
                                    handleUpload()
                                }}
                                disabled={!title}
                            >
                                Upload
                            </Button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default VehiclesModal
