import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik } from 'formik'
import { Button, Form, FormGroup, Label, Modal, Spinner, Table } from 'reactstrap'
import { selectors } from 'features/dashboard'
import {
    createToken,
    deleteAccessToken,
    getAccessToken,
    getProfile,
    updateProfile,
    verifyPhoneNumber,
    initiatePhoneVerification,
} from 'features/user/actionTypes'
import { customInputForm } from 'ui/common'
import { TIMEZONES } from 'export'
import DropdownSelect from 'layout-components/Dropdown'
import { validateDate } from '../organization/OrgTeamHelpers'
import dayjs from 'dayjs'
import { PhoneNumberSchema } from 'schemas/user'

const MyDetails = () => {
    const dispatch = useDispatch()
    const user = useSelector(selectors.getUser)
    const [dragActive, setDragActive] = useState(false)
    const [showSMSInput, setShowSMSInput] = useState(false)
    const [smsCode, setSmsCode] = useState('')
    // eslint-disable-next-line
    const [smsSubmitting, setSmsSubmitting] = useState(false)
    // eslint-disable-next-line
    const [verificationStatus, setVerificationStatus] = useState(
        user.profile.is_phone_verified ? 'verified' : 'unverified'
    )
    const [imageUrl, setImageUrl] = useState('')
    const [timezone, setTimezone] = useState(user.profile.timezone)
    const [createAccessToken, setCreateAccessToken] = useState(false)
    const [accessToken, setAccessToken] = useState(null)
    const [initToken, setInitToken] = useState(false)
    const [modalTokenDelete, setModalTokenDelete] = useState(false)
    const [countdown, setCountdown] = useState(60);
    useEffect(() => {
        if (!showSMSInput || countdown === 0) return;
        const interval = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [showSMSInput, countdown]);
    const inputFile = useRef(null)

    const toggleModalTokenDelete = () => setModalTokenDelete(!modalTokenDelete)
    const handleUpdateProfile = (data, { setErrors, setSubmitting }) => {
        const { name } = data // eslint-disable-line
        setSubmitting(true)
        dispatch(updateProfile(data)).then(({ error }) => {
            if (error) {
                setErrors(error.response.data)
                setSubmitting(false)
            } else {
                setSubmitting(false)
                dispatch(getProfile())
            }
        })
    }
    const handleVerifyPhone = (smsCode, phoneNumber, setSubmitting) => {
        setSubmitting(true);
        dispatch(verifyPhoneNumber({ sms_code: smsCode, phone_number: phoneNumber, uuid: user.profile.uuid })).then(({ error }) => {
            if (error) {
                console.error(error.response.data);
                setSubmitting(false);
            } else {
                setSubmitting(false);
                setVerificationStatus('verified');
                setShowSMSInput(false);
                dispatch(getProfile());
            }
        });
    };

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true)
        } else if (e.type === 'dragleave') {
            setDragActive(false)
        }
    }

    const handleDrop = (e, setFieldValue) => {
        const { files } = e.dataTransfer
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (files && files.length) {
            const imageUrl = URL.createObjectURL(files[0])
            setFieldValue('image', files[0])
            setImageUrl(imageUrl)
        }
    }

    const handleFileChange = (event, setFieldValue) => {
        const file = event.currentTarget.files[0]
        if (file) {
            // You can use URL.createObjectURL to create a temporary URL for the selected image.
            const imageUrl = URL.createObjectURL(file)
            setFieldValue('image', file)
            setImageUrl(imageUrl)
        }
    }

    return (
        <div style={{ paddingRight: '32px' }}>
            <div className="mt-5">
                <h4>Personal info</h4>
                <span>Update your personal details and photo here.</span>
            </div>
            <hr />
            <Formik
                initialValues={{
                    uuid: user.profile.uuid,
                    email: user.profile.email,
                    username: user.profile.username,
                    first_name: user.profile.first_name,
                    last_name: user.profile.last_name,
                    timezone: user.profile.timezone,
                    phone_number: user.profile.phone_number || '',
                }}
                enableReinitialize={true}
                validationSchema={PhoneNumberSchema}
                onSubmit={(data, action) => {
                    const formData = new FormData()
                    Object.keys(data).forEach((key) => {
                        formData.append(key, data[key])
                    })
                    handleUpdateProfile(formData, action)
                }}
            >
                {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="name" style={{ fontWeight: 'bold' }}>
                                Name
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup style={{ marginRight: '2rem' }}>
                                    <Field
                                        id="name"
                                        component={customInputForm}
                                        type="text"
                                        name="first_name"
                                        placeholder="First Name"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '13rem',
                                        }}
                                        value={values.first_name || ''}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        component={customInputForm}
                                        type="text"
                                        name="last_name"
                                        placeholder="Last Name"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '13rem',
                                        }}
                                        value={values.last_name || ''}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="email" style={{ fontWeight: 'bold' }}>
                                Email
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup>
                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        component={customInputForm}
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.email || ''}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="usernmae" style={{ fontWeight: 'bold' }}>
                                Username
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup>
                                    <Field
                                        component={customInputForm}
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.username || ''}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '0px 360px 32px 0px', minHeight: '220px' }}
                        >
                            <Label for="phone_number" style={{ fontWeight: 'bold' }}>
                                Phone Number
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup>
                                    <Field
                                        id="phone_number"
                                        name="phone_number"
                                        type="tel"
                                        component={customInputForm}
                                        placeholder="Enter your phone number"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',

                                        }}
                                        value={values.phone_number || ''}
                                    disabled={showSMSInput}
                                    />

                                    {values.phone_number === user.profile.phone_number && (
                                        user.profile.is_phone_verified ? (
                                            <span style={{
                                                backgroundColor: '#e6f4ea',
                                                color: '#137333',
                                                padding: '4px 8px',
                                                borderRadius: '6px',
                                                fontWeight: '500',
                                                marginTop: '0.5rem',
                                                display: 'inline-block'
                                            }}>
                                                  Phone number verified
                                              </span>
                                        ) : (
                                            <>
                                                {showSMSInput ? (
                                                    <span style={{
                                                        backgroundColor: '#f0f4ff',
                                                        color: '#004085',
                                                        padding: '4px 8px',
                                                        borderRadius: '6px',
                                                        fontWeight: '500',
                                                        marginTop: '0.5rem',
                                                        display: 'inline-block'
                                                    }}>
                                                          Phone verification initiated
                                                      </span>
                                                ) : (
                                                    <span style={{
                                                        backgroundColor: '#fff8f8',
                                                        color: '#a00',
                                                        padding: '4px 8px',
                                                        borderRadius: '6px',
                                                        fontWeight: '500',
                                                        marginTop: '0.5rem',
                                                        display: 'inline-block'
                                                    }}>
                                                          Phone number unverified
                                                          <span
                                                              style={{
                                                                  marginLeft: '10px',
                                                                  textDecoration: 'underline',
                                                                  cursor: 'pointer',
                                                                  color: '#007bff'
                                                              }}
                                                              onClick={() => {
                                                                  setShowSMSInput(true);
                                                                  dispatch(initiatePhoneVerification(values.phone_number, values.uuid));
                                                              }}
                                                          >
                                                              Click here to verify
                                                          </span>
                                                      </span>
                                                )}
                                            </>
                                        )
                                      )}

                                    <div
                                        className="d-flex flex-column mt-2"
                                        style={{
                                            maxHeight: showSMSInput ? '200px' : '0',
                                            opacity: showSMSInput ? 1 : 0,
                                            overflow: 'hidden',
                                            transition: 'all 0.3s ease',
                                            minHeight: showSMSInput ? '70px' : '0',
                                        }}
                                    >
                                            <small>You will receive a code via SMS. Enter it here:</small>

                                            <div className="d-flex align-items-center mt-2">
                                                <input
                                                    type="text"
                                                    placeholder="Verification code"
                                                    value={smsCode}
                                                    onChange={(e) => setSmsCode(e.target.value)}
                                                    className="form-control"
                                                    style={{
                                                        border: '1px solid #ccc',
                                                        borderRadius: '10px',
                                                        padding: '8px',
                                                        fontSize: '16px',
                                                        height: '2rem',
                                                        width: '10rem',
                                                        marginRight: '1rem',
                                                    }}
                                                />
                                                <Button
                                                    className="save-btn-modal modals-new-btns"
                                                    style={{ height: '2rem', padding: '0 1rem' }}
                                                    onClick={() => {
                                                        handleVerifyPhone(smsCode, values.phone_number, setSmsSubmitting);
                                                    }}
                                                    disabled={!smsCode}
                                                >
                                                    Verify
                                                </Button>
                                                <Button
                                                    className="cancel-btn-modal modals-new-btns"
                                                    style={{ height: '2rem', padding: '0 1rem', marginLeft: '0.5rem', color: 'red', background: 'transparent', border: 'none' }}
                                                    onClick={() => {
                                                        setShowSMSInput(false);
                                                        setSmsCode('');
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <small style={{ marginTop: '0.5rem' }}>
                                                Didn't receive the code?{' '}
                                                <span
                                                    style={{
                                                        textDecoration: countdown === 0 ? 'underline' : 'none',
                                                        color: countdown === 0 ? '#007bff' : '#6c757d',
                                                        cursor: countdown === 0 ? 'pointer' : 'default',
                                                    }}
                                                    onClick={() => {
                                                        if (countdown === 0) {
                                                            setCountdown(60); // reset
                                                            dispatch(initiatePhoneVerification(values.phone_number, values.uuid));
                                                        }
                                                    }}
                                                >
                                                            here
                                                        </span>{' '}
                                                ({countdown}s)
                                            </small>
                                        </div>
                                </FormGroup>
                            </div>
                        </div>

                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-start"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <div className="d-flex flex-column">
                                <Label for="image" style={{ fontWeight: 'bold' }}>
                                    Your photo
                                </Label>
                                <span>This will be displayed on your profile</span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                {values.image ? (
                                    <div
                                        className="rounded-circle"
                                        style={{
                                            width: '72px',
                                            height: '72px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            id="user"
                                            src={imageUrl}
                                            alt="user"
                                            width={72}
                                            height={72}
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                ) : user.profile.image ? (
                                    <div
                                        className="rounded-circle"
                                        style={{
                                            width: '72px',
                                            height: '72px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            id="user"
                                            src={user.profile.image}
                                            alt="user"
                                            width={72}
                                            height={72}
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <img
                                        id="user"
                                        src="/svgs/page-title-icons/user.svg"
                                        alt="user"
                                        width={72}
                                        height={72}
                                    />
                                )}
                                <div
                                    className={`d-flex flex-column justify-content-center align-items-center p-5 border rounded ml-2 ${
                                        dragActive ? 'border-gray' : ' border-meili'
                                    }`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => inputFile.current.click()}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={(e) => handleDrop(e, setFieldValue)}
                                >
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={inputFile}
                                        accept={'.png, .pgm, .svg, .jpg'}
                                        onChange={(event) => handleFileChange(event, setFieldValue)}
                                    />
                                    <img
                                        id="upload"
                                        src="/svgs/settings-page/upload.svg"
                                        alt="user"
                                        width={48}
                                        height={48}
                                    />

                                    <span className="my-2" style={{ fontWeight: 'bold' }}>
                                        <span className="text-meili">Click to upload</span> or drag
                                        an drop
                                    </span>
                                    <span>JPG, PNG or SVG (max. 400 x 400px)</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-start"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <div className="d-flex flex-column">
                                <Label for="image" style={{ fontWeight: 'bold' }}>
                                    User persistent token authentication
                                </Label>
                                <span style={{ maxWidth: '300px' }}>
                                    Persistent tokens are like user tokens but won't expire or be
                                    invalidated upon logout.
                                </span>
                            </div>
                            <div
                                className="d-flex justify-content-center"
                                style={{ width: '28rem' }}
                            >
                                <FormGroup className="w-100">
                                    {user.accessToken && user.accessToken.length === 0 ? (
                                        createAccessToken ? (
                                            <div className="w-100 d-flex flex-column justify-content-center">
                                                <span className="org-modal-header mb-2">
                                                    Create access token
                                                </span>
                                                <FormGroup>
                                                    <Field
                                                        component={customInputForm}
                                                        type="text"
                                                        name="name"
                                                        placeholder="Token name"
                                                        style={{
                                                            border: '1px solid #ccc',
                                                            borderRadius: '10px',
                                                            padding: '8px',
                                                            fontSize: '16px',
                                                            height: '2rem',
                                                            width: '28rem',
                                                        }}
                                                        value={values.name || ''}
                                                    />
                                                </FormGroup>
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        className="cancel-btn-modal modals-new-btns"
                                                        onClick={() => {
                                                            setCreateAccessToken(false)
                                                            setFieldValue('name', '')
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="save-btn-modal modals-new-btns"
                                                        style={{ marginLeft: '12px' }}
                                                        onClick={() => {
                                                            dispatch(
                                                                createToken({
                                                                    data: { name: values.name },
                                                                }),
                                                            ).then((res) => {
                                                                if (!res.error) {
                                                                    setFieldValue('name', '')
                                                                    setCreateAccessToken(false)
                                                                    setAccessToken(res.payload.data)
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        Create token
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : accessToken ? (
                                            <div className="w-100 d-flex flex-column align-items-center gap-2">
                                                <span>
                                                    Your access token, save it somewhere safe.
                                                </span>
                                                <span className="org-modal-header">
                                                    "{accessToken.key}"
                                                </span>
                                                <Button
                                                    className="save-btn-modal modals-new-btns w-100 mt-2"
                                                    onClick={() => {
                                                        dispatch(getAccessToken())
                                                    }}
                                                >
                                                    Done
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button
                                                className="w-100 cancel-btn-modal modals-new-btns"
                                                style={{ height: '2.5rem' }}
                                                onClick={() => setCreateAccessToken(true)}
                                            >
                                                Create access token
                                            </Button>
                                        )
                                    ) : (
                                        <div
                                            className="big-org-container"
                                            style={{ padding: '0', marginTop: '32px' }}
                                        >
                                            <Table bordered className="my-custom-table border-0">
                                                <thead>
                                                <tr>
                                                    <th className="react-table-header">Name</th>
                                                    <th className="react-table-header">
                                                        Created at
                                                    </th>
                                                    <th className="react-table-header">Site</th>
                                                    <th className="react-table-header"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {user?.accessToken?.map((token) => (
                                                    <tr key={token.uuid}>
                                                        <td className="react-table-style">
                                                            {token.name}
                                                        </td>
                                                        <td className="react-table-style text-dark">
                                                            {validateDate(dayjs(token.created))}
                                                        </td>
                                                        <td className="react-table-style">
                                                            {token.site.split('.')[0]}
                                                        </td>
                                                        <td>
                                                            <img
                                                                style={{ cursor: 'pointer' }}
                                                                src="/svgs/organization/buttons/trash.svg"
                                                                alt="trash"
                                                                onClick={() => {
                                                                    setInitToken(token)
                                                                    setModalTokenDelete(true)
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="timezone" style={{ fontWeight: 'bold' }}>
                                Timezone
                            </Label>
                            <div
                                className="d-flex justify-content-center mt-2"
                                style={{ width: '28rem' }}
                            >
                                <FormGroup className="w-100">
                                    <DropdownSelect
                                        selected={timezone}
                                        setSelected={setTimezone}
                                        options={TIMEZONES.map((timezone) => {
                                            return { uuid: timezone, name: timezone }
                                        })}
                                        setFieldValue={setFieldValue}
                                        fieldValue="timezone"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-end mr-3 my-4">
                            <Button
                                type="submit"
                                className="save-btn-modal modals-new-btns"
                                style={{ height: '2.5rem', width: '5rem' }}
                                disabled={JSON.stringify(values) === JSON.stringify(user.profile)}
                            >
                                Save
                                {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <Modal isOpen={modalTokenDelete} toggle={toggleModalTokenDelete} centered>
                <div className="d-flex flex-column justify-content-center p-3">
                    <span className="org-modal-header">Delete access token</span>
                    <span>
                        Are you sure you want to delete {initToken?.name} token? This cannot be
                        undone.
                    </span>
                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            className="cancel-btn-modal modals-new-btns"
                            onClick={toggleModalTokenDelete}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="save-btn-modal modals-new-btns"
                            style={{ marginLeft: '12px' }}
                            onClick={() => {
                                setInitToken(null)
                                setAccessToken(null)
                                setCreateAccessToken(false)
                                dispatch(deleteAccessToken(initToken?.uuid)).then((res) => {
                                    if (!res.error) {
                                        dispatch(getAccessToken())
                                        toggleModalTokenDelete()
                                    }
                                })
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MyDetails
