import React, { useState } from 'react'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { FormGroup, Label, Button, Input, ButtonGroup } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { selectors } from 'features/dashboard'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import {
    canRedo,
    canUndo,
    createZonesPath,
    getMapStations,
    getZonesPath,
    getZonesPaths,
    updateZonesPath,
} from 'features/dashboard/Map.actionTypes'
import { HiOutlineTrash } from 'react-icons/hi'
import { removeNullFromObj } from 'utils'
import DropdownSelect from 'layout-components/Dropdown'

const ZonesPathModal = ({    toggle, stations, path, setWindows, areaUuid, status }) => {
    const dispatch = useDispatch()
    const [from, setFrom] = useState('')
    const [tab, setTab] = useState('general')
    const [to, setTo] = useState('')
    const iconColor = path?.path_color ? path?.path_color : '#BA4FFA'
    const [color, setColor] = useState(iconColor)

    const setInitialValues = () => {
        if (tab === 'appearance' && status === 'edit')
            return {
                uuid: path.uuid,
                path_color: path.path_color,
                apply_color_choice_to: path.apply_color_choice_to,
            }
        else {
            if (path) {
                return {
                    uuid: path.uuid,
                    bidirectional: path.bidirectional,
                    source_point: path.source_point,
                    destination_point: path.destination_point,
                    speed_limit: path.speed_limit === -1 ? null : path.speed_limit,
                    switch_direction: false,
                    corridor_width: path.corridor_width,
                }
            } else
                return {
                    source_point: '',
                    destination_point: '',
                    bidirectional: true,
                    speed_limit: null,
                    corridor_width: null,
                }
        }
    }

    const handleSubmit = (data) => {
        const cleanData = removeNullFromObj(data)
        if (data.uuid) {
            if (tab === 'appearance' && status === 'edit') {
                dispatch(updateZonesPath({ uuid: data.uuid, data: cleanData })).then(
                    ({ error }) => {
                        if (error) return
                        dispatch(getZonesPath(data.uuid))
                        data.apply_color_choice_to && dispatch(getZonesPaths(areaUuid))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                    }
                )
            } else {
                const modData = cleanData.switch_direction
                    ? {
                          ...cleanData,
                          source_point: cleanData.destination_point,
                          destination_point: cleanData.source_point,
                      }
                    : {
                          uuid: cleanData.uuid,
                          bidirectional: cleanData.bidirectional,
                          speed_limit: cleanData.speed_limit === '' ? null : cleanData.speed_limit,
                          corridor_width: cleanData.corridor_width,
                      }

                dispatch(updateZonesPath({ uuid: data.uuid, data: modData })).then(({ error }) => {
                    if (!error) {
                        dispatch(getZonesPath(data.uuid))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                    }
                })
            }
        } else {
            dispatch(createZonesPath({ uuid: areaUuid, data: cleanData })).then(({ error }) => {
                if (!error) {
                    dispatch(getMapStations({ uuid: areaUuid }))
                    dispatch(getZonesPath(areaUuid))
                    dispatch(canRedo(areaUuid))
                    dispatch(canUndo(areaUuid))
                }
            })
        }
        toggle()
    }
    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    src="/svgs/modal-icons/path-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <div>
                <span className="org-modal-header">
                    {status === 'add-path' ? 'New path' : 'Path settings'}
                </span>

                <div className="modals-container-buttons">
                    <ButtonGroup className="org-button-group">
                        <Button
                            className={`${
                                tab === 'general'
                                    ? ' org-button-selected weight-500 size-14 grey-800'
                                    : ''
                            } org-button`}
                            style={{ borderRadius: '6px' }}
                            onClick={() => {
                                setTab('general')
                            }}
                        >
                            General
                        </Button>
                        {status === 'edit' && (
                            <Button
                                className={`${
                                    tab === 'appearance'
                                        ? ' org-button-selected weight-500 size-14 grey-800'
                                        : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('appearance')
                                }}
                            >
                                Appearance
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
            <Formik initialValues={setInitialValues()} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        {tab === 'general' && (
                            <>
                                {!path && (
                                    <>
                                        <FormGroup>
                                            <Label className="modals-labels">From *</Label>
                                            <DropdownSelect
                                                selected={from}
                                                setSelected={setFrom}
                                                options={stations?.map((station) => station)}
                                                setFieldValue={setFieldValue}
                                                fieldValue="source_point"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels">To *</Label>
                                            <DropdownSelect
                                                selected={to}
                                                setSelected={setTo}
                                                options={stations.map((station) => station)}
                                                setFieldValue={setFieldValue}
                                                fieldValue="destination_point"
                                            />
                                        </FormGroup>
                                    </>
                                )}
                                <FormGroup>
                                    <Label className="modals-labels">Speed limit</Label>
                                    <div className="d-flex align-items-center">
                                        <Field
                                            min={0}
                                            step={0.1}
                                            type="number"
                                            name="speed_limit"
                                            className="modals-inputs"
                                            value={values.speed_limit || ''}
                                            component={customInputForm}
                                        />
                                        <span
                                            className="modals-labels"
                                            style={{ marginLeft: '-6.2rem' }}
                                        >
                                            metres/s
                                        </span>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels">Corridor width</Label>
                                    <div className="d-flex align-items-center">
                                        <Field
                                            min={0}
                                            step={0.1}
                                            type="number"
                                            name="corridor_width"
                                            className="modals-inputs"
                                            value={values.corridor_width || ''}
                                            component={customInputForm}
                                        />
                                        <span
                                            className="modals-labels"
                                            style={{ marginLeft: '-6.2rem' }}
                                        >
                                            metres
                                        </span>
                                    </div>
                                </FormGroup>
                                <FormGroup check className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        id="bidirectional"
                                        aria-label="Bidirectional"
                                        checked={values.bidirectional}
                                        onChange={() => {
                                            setFieldValue('bidirectional', !values.bidirectional)
                                        }}
                                    />
                                    <Label check className="modals-labels mt-1">
                                        Bidirectional{' '}
                                    </Label>
                                </FormGroup>
                                {path && !values.bidirectional && (
                                    <FormGroup check className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="switch_direction"
                                            aria-label="Switch direction"
                                            checked={
                                                values?.switch_direction === false ? false : true
                                            }
                                            onChange={() => {
                                                setFieldValue(
                                                    'switch_direction',
                                                    !values.switch_direction
                                                )
                                            }}
                                        />
                                        <Label check className="modals-labels mt-1">
                                            Switch direction{' '}
                                        </Label>
                                    </FormGroup>
                                )}
                            </>
                        )}
                        {tab === 'appearance' && (
                            <>
                                <div>
                                    <FormGroup>
                                        <Label className="grey-900 weight-500 size-14">
                                            Path colour
                                        </Label>
                                        <label className="color-selector modals-inputs">
                                            <span
                                                className="circle"
                                                style={{ background: color }}
                                            />
                                            <span className="grey-400">{color}</span>
                                            <Field
                                                style={{
                                                    marginLeft: '100px',
                                                }}
                                                type="color"
                                                id="path_color"
                                                name="path_color"
                                                value={values.path_color || ''}
                                                onChange={(e) => {
                                                    const color = e.target.value
                                                    setColor(color)
                                                    setFieldValue('path_color', color)
                                                }}
                                                className="hidden"
                                            />
                                        </label>
                                    </FormGroup>

                                    <div className="d-flex">
                                        <FormGroup
                                            switch
                                            className="d-flex flex-column mb-2 ml-3 mb-3"
                                        >
                                            <Input
                                                style={{ height: '20px', width: '36px' }}
                                                role="switch"
                                                type="switch"
                                                id="apply_color_choice_to"
                                                className="custom-switch-form modals-labels"
                                                checked={values.apply_color_choice_to || ''}
                                                name="apply_color_choice_to"
                                                onChange={(e) => {
                                                    setFieldValue(e.target.name, 'all')
                                                }}
                                            />
                                            <Label
                                                check
                                                className="mt-1 grey-900 weight-400 size-14"
                                                style={{ marginLeft: '8px' }}
                                            >
                                                Apply to all paths
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>
                            </>
                        )}

                        <div
                            className={`d-flex pt-3 w-100 ${
                                path ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                             {status === 'edit' && tab === 'general' ? (
                            path ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setWindows({ removeZonesPath: true })
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete path
                                </Button>
                            ) : null ):

                            (
                                <Button
                                    className="reset-button modals-delete-btn"
                                    color="none"
                                    onClick={() => {
                                        setColor('#BA4FFA')
                                        setFieldValue('path_color', '#BA4FFA')    
                                    }}
                                >
                                    <img
                                        src="/svgs/map-layer/reset-btn.svg"
                                        alt="reset-icon"
                                        width="21px"
                                        height="21px"
                                    />

                                    <span style={{ marginLeft: '1px' }}> Reset to default</span>
                                </Button>
                            )}
                            <div className="d-flex">
                                <Button
                                    onClick={toggle}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                <Button type="submit" className="save-btn-modal modals-new-btns">
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

function mapStateToProps(state) {
    return {
        stations: selectors.getMapStations(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(ZonesPathModal)
